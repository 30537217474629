.buttonW {
    width: "100%";
    height: "310px";}

    .buttonD {
        width: "100%";
        height: "310px";
        color: white;
    }

    .MuiSlider-thumb.MuiSlider-thumbColorPrimary{
        height: 15px;
        width: 15px;
        font-size: 10px;
        color: #ff6a03;
    }
    .MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-marked{
        height: 15px;
        width: 300px;
        font-size: 10px;
        color:hsl(295, 93%, 48%);

        margin-left: 6px;
        margin-right: 10px;
    }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputMarginDense.MuiInput-inputMarginDense{

        margin-left: 10px;
        margin-right: 5px;
        height: 35px;
        width: 240px;
        font-size: 22px;
        color: #ff6a03;
        
    }
    .myHeader{
        text-align: left;

        margin-left: 25%;
    }
    .makeStyles-slider-23{
        width: 100% !important;
        max-width: 20px !important;
      }

      .Bab{
        width: 100% !important;
        max-width: 90% !important;
      }
